import * as React from 'react'
import { graphql } from 'gatsby'


import Layout from '../components/layout'
import { BlogPosts } from '../components/BlogPosts'
import PageTitle from "../components/pagetitle"
import Seo from "../components/seo"

export const query = graphql`
  query MyQuery($limit: Int!, $skip: Int!) {
    prismicBloghome {
      _previewable
      data {
        description {
          text
        }
        headline {
          text
        }
        image {
          url
        }
      }
    }
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        _previewable
        id
        url
        data {
          title {
            text
          }
          date(formatString: "MMM D, YYYY")
          body {
            ... on PrismicPostDataBodyText {
              id
              slice_label
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

const Homepage = ({ data }) => {
  if (!data) return null
  const home = data.prismicBloghome.data
  const docs = data.allPrismicPost

  return (
    <Layout>
      <PageTitle title={`NVT Managed Services Blog - Page ${data.allPrismicPost.pageInfo.currentPage}`} page="PageTitleServices" />
      <Seo title={`NVT Managed Services Blog - Page ${data.allPrismicPost.pageInfo.currentPage}`}  description={`Our Managed Services Blog is designed to help dental software users, accounting software business owners, and other small business IT support needs. - Page ${data.allPrismicPost.pageInfo.currentPage}`} />
      <div className="content-page-wrapper">
        <BlogPosts docs={docs} />
      </div>

    </Layout>
  )
}

export default Homepage;
